import client from "./_client.js";

import {
  getme,
  sendverifyemail,
  checkusername,
  getmystats,
  updateuser,
  updatepersonaldetails,
  updatepassword,
  logout,
  updateusername,
  updateProfileCpStatus,
  getAuthUserFollowers,
  getAuthUserFollowing,
  validateTaggedUsers,
  // creating
  createProject,
  createEducation,
  createExperience,
  createCertificate,
  createAward,
  // updating
  updateSkills,
  updateCertificates,
  updateAwards,
  updateSocials,
  updateContact,
  updateProject,
  updateExperience,
  updateEducation,
  updateAbout,
  getUserDetails,
  getProfileDetails,
  //deleting apis
  deleteProject,
  deleteEducation,
  deleteExperience,
  deleteCertificate,
  deleteAward,
} from "./auth.js";

import {
  login,
  googlesignin,
  register,
  resetpassword,
  team,
  getuser,
  getCertificatesAndCP,
  getTopLearners,
  getuserprofile,
  getRecommendedUsers,
  getuserinsights,
  popularcreators,
  gettopperformer,
  checkCreatorAccess,
  getsearchresult,
  updateShowTour,
  updateInvitedUser,
  banUser,
  generateOtp,
  verifyOtp,
} from "./user.js";

import {
  getComments,
  saveComment,
  likeComment,
  unlikeComment,
  deleteComment,
} from "./comments.js";

import {
  category,
  carousals,
  prizes,
  prize,
  iKitAuth,
  addAlumni,
  getProgram,
  getAlumnis,
  checkSlug,
  takeAlumniConsent,
  removeImageFromIKit,
} from "./others.js";

import {
  createassignment,
  updateassignment,
  getassignment,
  getassignmentsbyme,
  getassignmentcount,
  deleteassignment,
} from "./assignment.js";

import {
  getvideo,
  checkUniqueVideoTitle,
  getsrc,
  getcoursevideos,
  getrecommendedvideos,
  getsuggestionvideos,
  videosbycreator,
  getKeys,
  isVideoRestricted,
  //UPLOAD
  profileUpload,
  mediaUpload,
  thumbnailUpload,
  uploadPromoVideo,
  uploadCurriculum,
  uploadTechnologyImage,
  //POST
  savevideo,
  updatevideo,
  deletevideo,
  // v2 apis
  getVideoV2,
  getsuggestionvideosV2,
  addVideoLectureV2,
  updateVideoLectureV2,
} from "./video.js";

import {
  getcourse,
  getallcourse,
  mycourses,
  updatecourse,
  coursesbycreator,
  getfilteredcourse,
  getUnwrapCourse,
  getlatestbyfollowing,
  getcoursesbyctg,
  gettrendcourses,
  deletecourse,
  // v2 apis
  getcourseV2,
  getvideolink,
  createcoursev2,
  updateCourseV2,
  createtechnologiesv2,
  addcoursedetailsv2,
  getcoursedetailsv2,
  getprimarytechnologiesv2,
  getCategoriesv2,
  addcoursepricev2,
  addemailv2,
  updateEmailV2,
  publishcoursev2,
  getRestrictedCourses,
  addReferralDiscount,
  addOnCourse,
  updateAddOnCourse,
  getAddOnCourses,
  getAddonAbleCourses,
  getAddOnCourseDetails,
  deleteAddOnCourse,
} from "./courses.js";

import {
  createCohort,
  myCohorts,
  updateCohort,
  addCohortdetails,
  getCohortdetails,
  getCohortCurriculum,
  updateCohortCurriculum,
  addCohortEmail,
  updateCohortEmail,
  publishCohort,
  deleteCohort,
} from "./cohorts.js";

import {
  getbundledetails,
  createbundle,
  addbundledetails,
  addcoursetobundle,
  updateBundle,
  addBundlePrice,
  updateBundleOrder,
  addBundleEmails,
  updateBundleEmails,
  publishBundle,
  myBundles,
  deleteBundle,
  deleteCourseFromBundle,
  editCourseInBundle,
} from "./bundles.js";

import {
  getResume,
  createResume,
  getFirstPdf,
  saveResume,
  updateResume,
  updateTemplate,
  resetResumeData,
  deleteResumeData,
  fetchResumesData,
} from "./resume.js";

import {
  followuser,
  getNetwork,
  getAllNetwork,
  getFollowers,
  isFollowing,
  removeFollowing,
  getFollowerCount,
  getFollowing,
} from "./network.js";

import {
  fetchRestrictedCourse,
  myEnrollments,
  fetchEnrollments,
  courseEnroll,
  courseUnenroll,
  updateProgress,
  getTestEligibility,
  enrollStudentToTest,
} from "./enrollments.js";

import {
  getsubmissionbyme,
  getcoursescore,
  updateassignmentsubmission,
  submitAssignment,
} from "./submission.js";

import {
  addLike,
  getMyReactions,
  getLikedVideos,
  likeStatus,
  removeLike,
} from "./videos-likes.js";

import { addView, getVideoViews, getUserVideoViews } from "./videos-views.js";

import {
  addWatchLaterVideo,
  getAllWatchLaterVideos,
  removeWatchLaterVideo,
} from "./wishlist.js";

import { getWatchPoint, getUserActivity, getMissions } from "./points.js";

import { postReport, getUserReports } from "./reports.js";

import {
  postRequest,
  isUserRequestExist,
  getUserRequests,
  deleteUser,
} from "./requests.js";

import {
  postFeedback,
  isUserFeedbackExist,
  getUserFeedbacks,
} from "./feedbacks.js";

import {
  getNotifications,
  getUnreadNotifications,
  getMentions,
  getCommentMentionVideo,
} from "./notification.js";

import { createsubscription, deleteToken } from "./subscriptions.js";

import {
  addNote,
  getNote,
  getVideoNotes,
  getCourseNotes,
  videoTitles,
  allNotesByCourse,
  getNotesPdf,
  updateNote,
  deleteNote,
} from "./notes.js";

import {
  createStage,
  getCourseStages,
  getStageVideos,
  updateStage,
  // v2 api
  addStageDetailsV2,
  addStageContentV2,
  getStageDetailsV2,
  updateStageOrderV2,
  updateContentOrderV2,
  updateStageV2,
  updateStageContentV2,
  deleteStageV2,
  deleteContentV2,
  addStageEmailV2,
  getStageEmailV2,
  deleteStageEmailV2,
  getStageArticleV2,
  getStageContentV2,
} from "./course-stages.js";

import {
  getOrders,
  updateOrder,
  createOrder,
  getSingleOrder,
} from "./orders.js";

import {
  getCertificates,
  getCertificate,
  getCertificateByCourse,
  createCertificatePdf,
  isValidCertificate,
  getUserCertificates,
  regenerateCertificatePdf,
} from "./certificates.js";

import {
  getAllBatches,
  getBatch,
  getBatchCourse,
  getBatchResource,
  getMySubmission,
  getMyContents,
  markMentorAttendence,
  increaseResourceClickCount,
  addResource,
  addBatchAssignment,
  addBatchSubmission,
  updateResource,
  updateBatchAssignment,
  updateBatchSubmission,
  deleteResource,
  deleteBatchAssignment,
} from "./batches.js";

const apis = {
  client,
  //auth.js
  logout,
  getme,
  checkusername,
  getmystats,
  sendverifyemail,
  updateuser,
  updatepersonaldetails,
  updateusername,
  updateProfileCpStatus,
  getAuthUserFollowers,
  getAuthUserFollowing,
  validateTaggedUsers,
  updatepassword,
  createProject,
  createEducation,
  createExperience,
  createCertificate,
  createAward,
  updateSkills,
  updateCertificates,
  updateAwards,
  updateSocials,
  updateContact,
  updateProject,
  updateExperience,
  updateEducation,
  updateAbout,
  getUserDetails,
  getProfileDetails,
  deleteProject,
  deleteEducation,
  deleteExperience,
  deleteCertificate,
  deleteAward,
  //user.js
  login,
  googlesignin,
  register,
  resetpassword,
  team,
  getuser,
  getCertificatesAndCP,
  getTopLearners,
  getuserprofile,
  getRecommendedUsers,
  getuserinsights,
  popularcreators,
  gettopperformer,
  checkCreatorAccess,
  getsearchresult,
  updateInvitedUser,
  banUser,
  updateShowTour,
  generateOtp,
  verifyOtp,
  //assignment.js
  createassignment,
  updateassignment,
  getassignment,
  getassignmentsbyme,
  getassignmentcount,
  deleteassignment,
  //others.js
  category,
  carousals,
  prizes,
  prize,
  iKitAuth,
  addAlumni,
  getProgram,
  getAlumnis,
  checkSlug,
  takeAlumniConsent,
  removeImageFromIKit,
  //comment.js
  getComments,
  saveComment,
  likeComment,
  unlikeComment,
  deleteComment,
  //video.js
  getsrc,
  checkUniqueVideoTitle,
  getvideo,
  checkUniqueVideoTitle,
  getKeys,
  isVideoRestricted,
  getcoursevideos,
  getsuggestionvideos,
  getrecommendedvideos,
  videosbycreator,
  getVideoV2,
  getsuggestionvideosV2,
  //UPLOAD
  profileUpload,
  mediaUpload,
  thumbnailUpload,
  uploadPromoVideo,
  uploadCurriculum,
  uploadTechnologyImage,
  //video.js POST
  savevideo,
  updatevideo,
  deletevideo,
  addVideoLectureV2,
  updateVideoLectureV2,
  //course.js
  getcourse,
  getcourseV2,
  createcoursev2,
  updateCourseV2,
  createtechnologiesv2,
  addcoursedetailsv2,
  getcoursedetailsv2,
  getprimarytechnologiesv2,
  getCategoriesv2,
  addcoursepricev2,
  addemailv2,
  updateEmailV2,
  publishcoursev2,
  getRestrictedCourses,
  addReferralDiscount,
  addOnCourse,
  updateAddOnCourse,
  getAddOnCourses,
  getAddonAbleCourses,
  getAddOnCourseDetails,
  getallcourse,
  getfilteredcourse,
  getUnwrapCourse,
  getcoursesbyctg,
  gettrendcourses,
  updatecourse,
  coursesbycreator,
  mycourses,
  getlatestbyfollowing,
  deletecourse,
  getvideolink,
  deleteAddOnCourse,
  // cohorts
  createCohort,
  myCohorts,
  updateCohort,
  addCohortdetails,
  getCohortdetails,
  getCohortCurriculum,
  updateCohortCurriculum,
  addCohortEmail,
  updateCohortEmail,
  publishCohort,
  deleteCohort,
  //bundles
  getbundledetails,
  createbundle,
  addbundledetails,
  addcoursetobundle,
  updateBundle,
  addBundlePrice,
  updateBundleOrder,
  addBundleEmails,
  updateBundleEmails,
  publishBundle,
  myBundles,
  deleteBundle,
  deleteCourseFromBundle,
  editCourseInBundle,
  //resumes
  getResume,
  createResume,
  getFirstPdf,
  saveResume,
  updateResume,
  updateTemplate,
  resetResumeData,
  deleteResumeData,
  fetchResumesData,
  //network
  followuser,
  getNetwork,
  getAllNetwork,
  getFollowers,
  getFollowing,
  isFollowing,
  removeFollowing,
  getFollowerCount,
  //users-enrollments
  fetchRestrictedCourse,
  myEnrollments,
  fetchEnrollments,
  courseEnroll,
  courseUnenroll,
  updateProgress,
  getTestEligibility,
  enrollStudentToTest,
  //submissions
  getsubmissionbyme,
  getcoursescore,
  updateassignmentsubmission,
  submitAssignment,
  //video-likes
  addLike,
  getMyReactions,
  getLikedVideos,
  likeStatus,
  removeLike,
  //video-views
  addView,
  getVideoViews,
  getUserVideoViews,
  //watch-later-videos
  addWatchLaterVideo,
  getAllWatchLaterVideos,
  removeWatchLaterVideo,
  //Watch-points
  getWatchPoint,
  getUserActivity,
  getMissions,
  //Video-report
  postReport,
  getUserReports,
  //Request
  postRequest,
  isUserRequestExist,
  getUserRequests,
  deleteUser,
  //Feedback
  postFeedback,
  isUserFeedbackExist,
  getUserFeedbacks,
  //notification
  getNotifications,
  getUnreadNotifications,
  getMentions,
  getCommentMentionVideo,
  //subscriptions
  createsubscription,
  deleteToken,

  //notes
  addNote,
  getNote,
  getVideoNotes,
  getCourseNotes,
  videoTitles,
  allNotesByCourse,
  getNotesPdf,
  updateNote,
  deleteNote,
  //course-stage
  createStage,
  getCourseStages,
  getStageVideos,
  updateStage,
  //v2 api
  addStageDetailsV2,
  addStageContentV2,
  getStageDetailsV2,
  updateStageOrderV2,
  updateContentOrderV2,
  updateStageV2,
  updateStageContentV2,
  deleteStageV2,
  deleteContentV2,
  addStageEmailV2,
  getStageEmailV2,
  deleteStageEmailV2,
  getStageArticleV2,
  getStageContentV2,

  // orders
  getOrders,
  updateOrder,
  createOrder,
  getSingleOrder,

  // certificate
  getCertificates,
  getCertificate,
  getCertificateByCourse,
  createCertificatePdf,
  isValidCertificate,
  getUserCertificates,
  regenerateCertificatePdf,

  // batches
  getAllBatches,
  getBatch,
  getBatchCourse,
  getBatchResource,
  getMySubmission,
  getMyContents,
  markMentorAttendence,
  increaseResourceClickCount,
  addResource,
  addBatchAssignment,
  addBatchSubmission,
  updateResource,
  updateBatchAssignment,
  updateBatchSubmission,
  deleteResource,
  deleteBatchAssignment,
};
export default apis;
